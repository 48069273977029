<template>
  <div class="iframe">
    {{ itemView }}
  </div>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  name: 'Content',
  props: {
    item: Object,
  },
  setup(props) {
    const itemView = ref(null);
    watch(() => {
      itemView.value = props.item;
    });
    return {
      itemView,
    };
  },
};
</script>

<style scoped>
  .iframe {
    background: red;
    width: 100%;
    height: 100vh;
    border: none;
  }
</style>